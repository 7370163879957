<template>
    <Select
        v-model="selectedInHouse"
        v-bind="$attrs"
        :get-data="getOfferType"
        class="white"
        field="label"
        prop="value"
        :placeholder="placeholder">
        <template #text="{ option }">
            <span v-t="option.label"></span>
        </template>
        <template #selected-option="{ option }">
            <span v-t="option.label"></span>
        </template>
    </Select>
</template>

<script>
  import Select from "@/components/Common/Select/Select.vue";

  export default {
    name: "InHouseSelect",
    components: { Select },

    props: {
      value: {
        type: [Boolean, null],
        default: null
      },

      placeholder: {
        type: String,
        default: ""
      }
    },

    computed: {
      selectedInHouse: {
        get () {
          return this.value;
        },
        set (value) {
          this.$emit("input", value);
        }
      }
    },

    methods: {
      getOfferType () {
        const items = [
          {
            value: true,
            label: this.$t("admin.statistics.statistics.filters.values.type.true")
          },
          {
            value: false,
            label: this.$t("admin.statistics.statistics.filters.values.type.false")
          }
        ];
        return {
          items,
          count: items.length
        };
      }
    }
  };
</script>

<style scoped>

</style>